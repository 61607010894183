import anime from "../node_modules/animejs/lib/anime.es.js";

/////////////////////////////////////////////////////////////////////////////
//Keeping Copyright date current
/////////////////////////////////////////////////////////////////////////////

document.querySelector(".year").textContent = new Date().getFullYear();

/////////////////////////////////////////////////////////////////////////////
// Make Mobile Nav work
/////////////////////////////////////////////////////////////////////////////

const navBtn = document.querySelector(".btn-mobile-nav");

const headerEl = document.querySelector(".header");
navBtn.addEventListener("click", function () {
  headerEl.classList.toggle("nav-open");
});

const linkElements = document.querySelectorAll(".animeUnderline");

function animateLink(el, underlineColor) {
  anime.remove(el);
  anime({
    targets: el,
    easing: "linear",
    textDecorationColor: underlineColor,
    duration: 500,
  });
}

/////////////////////////////////////////////////////////////////////////////
// Animate Nav Elements
/////////////////////////////////////////////////////////////////////////////

linkElements.forEach((el) => {
  el.addEventListener("mouseenter", (e) => {
    animateLink(e.target, "rgba(0,0,0,1");
  });
});

linkElements.forEach((el) => {
  el.addEventListener("mouseleave", (e) => {
    animateLink(e.target, "rgba(0,0,0,0");
  });
});
